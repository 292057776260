export const environment = {
  production: false,
  hmr: true,
  // baseUrl:'http://192.168.20.202:8000', // smile pc
  baseUrl:'https://ikooapidev.elitetraveltech.in',
  // baseUrl:'http://192.168.20.201:8000', //  pc
  defaultLanguage: 'Label',
  supportedLanguages: ['Label', 'TMSA'],
};
   
